.pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0px 10px 5px 0px;
    gap: 5px;
  }
  
  #filter-text::placeholder,
  #filter-auto-complete::placeholder,
  .form-control::placeholder {
    font-style: oblique;
    color: #18181a;
    opacity: 0.62;
    font-size: small;
  }
  
  tr.tr-lightgreen td {
    background-color: #c8e6c9;
  }
  
  /* .tbl-grid > :not(caption) > * > * {
    padding: 0.09rem .25rem;
  } */
  
  .tbl-grid > tbody > * > * {
    padding: 0rem 0.25rem;
  }
  .table-sm > :not(caption) > * > * {
    padding: 0.15rem 0.25rem !important;
  }
  
  .tbl-grid > tfoot > tr {
    border-bottom: 2px solid #0b5f18 !important;
    border-top: 2px solid #0b5f18 !important;
    font-weight: bold;
  }
  
  .grid_checkbox {
    padding: 2px 9px !important;
  }
  
  .pagination-btn {
    border-radius: 0 !important;
    min-width: unset !important;
  }
  .pagination-btn > span {
    margin-right: unset;
  }
  table thead tr {
  border-bottom: 2px solid #0b5f18 !important;
  }