body {
  letter-spacing: 0.08rem;
}

.cus-page-layout {
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(12.5px);
  height: 91vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.dashboard-header {
  display: inline-flex;
  padding: 0 20px;
  align-items: center;
  gap: 10px;
  min-width: 447px;
  height: 40px;
  border-radius: 0px 30px 30px 0px;
  margin-top: 10px;
  /* background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.2) 100%
      ),
      linear-gradient(180deg, #beee00 0%, #277625 100%);
  
    background: #52c234;
    background: -webkit-linear-gradient(to right, #061700, #52c234);
    background: linear-gradient(to right, #061700, #52c234); */

  /* background: #c6ffdd;
  background: -webkit-linear-gradient(158deg, #f7797d, #fbd786, #c6ffdd);
  background: linear-gradient(158deg, #f7797d, #fbd786, #c6ffdd); */

  background-color: #fbab7e;
  background-image: linear-gradient(158deg, #fbab7e 0%, #f7ce68 100%);

  color: #fff;
}

.dashboard-header > span {
  color: #fff;
  /* font-family: Inter; */
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.drawer-background,
.MuiDrawer-paper {
  /* background-color: #fbab7e;
  background-image: linear-gradient(158deg, #fbab7e 0%, #f7ce68 100%); */

  /* background: #c6ffdd;
  background: -webkit-linear-gradient(180deg, #f7797d, #fbd786, #c6ffdd);
  background: linear-gradient(45deg, #f7797d, #fbd786, #c6ffdd); */

  /* background-color: #fbab7e;
  background-image: linear-gradient(45deg, #f7ce68 0%,#fbab7e  100%);
  background-image: -webkit-linear-gradient(45deg, #fbab7e 0%, #f7ce68 100%); */
  
  
  /* background: #F2994A;  
  background: -webkit-linear-gradient(to right, #F2C94C, #F2994A); 
  background: linear-gradient(to bottom, #F2C94C, #F2994A);  */
  


}

.form-control:focus {
  box-shadow: none;
}

.MuiDivider-root::before,
.MuiDivider-root::after {
  border-top: thin solid navy;
}

.border-dashed {
  border-left-style: dashed !important;

}

tfoot tr td{
  border-top: 1px solid #0b5f18;
}

.ck-editor__editable {
  min-height: 300px;
}