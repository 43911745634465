.custom-btn-page-layout {
    /* background-color: #085581 !important;
      border: 0px, 0px, 2px, 0px !important;
      border-bottom: 3px solid #03a8dc !important; */
    /* margin: 10px;
      padding: 15px 45px;
      text-align: center; */
    /* background-image: linear-gradient(
      to right,
      #43cea2 0%,
      #185a9d 51%,
      #43cea2 100%
    ) !important; */
    background-image: linear-gradient(
      to right,
      #004d40 0%,
      #00897b 51%,
      #004d40 100%
    ) !important;
  
    text-transform: uppercase;
    transition: 0.5s !important;
    background-size: 200% auto;
    color: white !important;
    box-shadow: 0 0 20px #eee;
    border: none;
    border-radius: 0 !important;
    /* border-radius: 10px;
      display: block; */
  }
  .custom-btn-page-layout:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }
  
  .custom-btn-success {
    /* background-image: linear-gradient(
      to right,
      #43cea2 0%,
      #185a9d 51%,
      #43cea2 100%
    ) !important; */
  
    background-image: linear-gradient(
      to right,
      #44a08d 0%,
      #093637 51%,
      #44a08d 100%
    ) !important;
  }
  
  .custom-btn-primary {
    background-image: linear-gradient(
      to right,
      #0d47a1 0%,
      #1e88e5 51%,
      #0d47a1 100%
    ) !important;
  }
  .custom-btn-blue {
    background-image: linear-gradient(
      to right,
      #00d2ff 0%,
      #3a7bd5 51%,
      #00d2ff 100%
    ) !important;
  }
  
  .custom-btn-danger {
    background-image: linear-gradient(
      to right,
      #b71c1c 0%,
      #e53935 51%,
      #b71c1c 100%
    ) !important;
  }
  
  .custom-btn-warning {
    background-image: linear-gradient(
      to right,
      #e65100 0%,
      #fb8c00 51%,
      #e65100 100%
    ) !important;
  }
  
  .custom-btn-secondary {
    background-image: linear-gradient(
      to right,
      #bdc3c7 0%,
      #2c3e50 51%,
      #bdc3c7 100%
    ) !important;
  }
  
  .custom-btn-upload {
    background-image: linear-gradient(
      to right,
      #000046 0%,
      #1cb5e0 51%,
      #000046 100%
    ) !important;
  }
  
  .custom-btn-login {
    background-image: linear-gradient(
      to right,
      #ff512f 0%,
      #f09819 51%,
      #ff512f 100%
    ) !important;
  }
  
  .custom-btn-red {
    background-image: linear-gradient(
      to right,
      #b71c1c 0%,
      #e53935 51%,
      #b71c1c 100%
    ) !important;
  }
  
  .custom-btn-pink {
    background-image: linear-gradient(
      to right,
      #880e4f 0%,
      #ec407a 51%,
      #880e4f 100%
    ) !important;
  }
  
  .custom-btn-purple {
    background-image: linear-gradient(
      to right,
      #4a148c 0%,
      #8e24aa 51%,
      #4a148c 100%
    ) !important;
  }
  
  .custom-btn-deeppurple {
    background-image: linear-gradient(
      to right,
      #311b92 0%,
      #5e35b1 51%,
      #311b92 100%
    ) !important;
  }
  
  .custom-btn-teal {
    background-image: linear-gradient(
      to right,
      #004d40 0%,
      #00897b 51%,
      #004d40 100%
    ) !important;
  }
  
  .custom-btn-green {
    background-image: linear-gradient(
      to right,
      #1b5e20 0%,
      #43a047 51%,
      #1b5e20 100%
    ) !important;
  }
  
  .custom-btn-grey {
    background-image: linear-gradient(
      to right,
      #212121 0%,
      #757575 51%,
      #212121 100%
    ) !important;
  }
  
  .custom-btn-white {
    background-image: linear-gradient(
      to right,
      #8e9eab 0%,
      #eef2f3 51%,
      #8e9eab 100%
    ) !important;
  }
  
  .custom-btn-black {
    background-image: linear-gradient(
      to right,
      #000000 0%,
      #434343 51%,
      #000000 100%
    ) !important;
  }
  
  .no-shadow {
    box-shadow: none !important;
  }
  
  .select {
    word-wrap: normal;
    background: #fff;
    border-radius: 3px;
    border: 1px solid #dee2e6;
    height: 30px;
  }
  
  .MuiButtonBase-root {
    border-radius: 0 !important;
  }
  